<template>
  <div class="airwallex">
    <div class="airwallex-title">
      Buy Coins
    </div>
    <div class="airwallex-text">
      For the Quick Pass
    </div>
    <div class="airwallex-text">
      Redeem game time
    </div>
    <div class="card-heng">
      <img
        class="img"
        src="../../assets/img/app/payicon.png"
        alt=""
      >
      <section class="card-heng-title">
        Account Balance
      </section>
      <section class="card-heng-num">
        {{ AccountBalance }}
      </section>
      <section class="card-heng-danw">
        coins
      </section>
    </div>
    <div class="pay-desc">
      Exchange Game Time
      <p>120 coins = 1 hour. Play any games you want to play.</p>
    </div>
    <div class="card-shu">
      <div
        v-for="(item,index) of userList"
        :key="index"
        class="card-list"
        @click="toPay(item.goodsId)"
      >
        <div class="card-list-decs">
          {{ item.gold }} Coins
        </div>
        <div class="card-list-btn">
          {{ item.currency }}＄{{ item.price }}
        </div>
      </div>
      <!-- <div class="card-list">
        <div class="card-list-decs">
          500 Coins
        </div>
        <div class="card-list-btn">
          US＄1.99
        </div>
      </div>
      <div class="card-list">
        <div class="card-list-decs">
          500 Coins
        </div>
        <div class="card-list-btn">
          US＄1.99
        </div>
      </div>
      <div class="card-list">
        <div class="card-list-decs">
          500 Coins
        </div>
        <div class="card-list-btn">
          US＄1.99
        </div>
      </div> -->
      <div class="pay-desc">
        After opening, plan automatically renews until canceled.
      </div>
    </div>
  </div>
</template>

<script>
import { zoneGoodsGold } from '@/api/airwallex.js'
import { paymentIntents } from '@/api/pay.js'
import { mapMutations } from 'vuex'
import {
  showBuyState
} from '@/utils'
export default {
  data () {
    return {
      userList: [],
      AccountBalance: ''
    }
  },
  created () {
    localStorage.utoken = this.$route.query.token || ''
    this.SET_TOKEN(this.$route.query.token)
    console.log(localStorage.utoken)
    window.Airwallex.init({
      env: process.env.VUE_APP_PAY_ENV, // Setup which Airwallex env('demo' | 'prod') to integrate with
      origin: window.location.origin // Set up your event target to receive the browser events message
    })
  },
  mounted () {
    this.AccountBalance = this.$route.query.AccountBalance || ''
    // this.userList = [{
    //   appId: 20,
    //   goodsId: 10019,
    //   name: '200 Gold',
    //   sku: 'netboom_andr_00',
    //   currency: 'SGD',
    //   price: 2.99,
    //   gold: 200,
    //   content: ''
    // },
    // {
    //   appId: 20,
    //   goodsId: 10102,
    //   name: 'test coins',
    //   sku: 'coins_test_100',
    //   currency: 'SGD',
    //   price: 1.99,
    //   gold: 100,
    //   content: ''
    // },
    // {
    //   appId: 20,
    //   goodsId: 10100,
    //   name: '500 Gold',
    //   sku: 'netboom_andr_22',
    //   currency: 'SGD',
    //   price: 6.99,
    //   gold: 500,
    //   content: ''
    // }]
    if (this.$route.query.AccountBalance && this.$route.query.token) {
      zoneGoodsGold({
        group: 2, channel: 1
      })
        .then(res => {
          if (res.code === 200) {
            console.log(res)
            this.userList = res.data
          } else {
            this.$toast.fail({
              message: res.msg
            })
          }
        })
    }
    if (this.$route.query.issuccess) {
      if (this.$route.query.issuccess === '1') {
        showBuyState(true)
      } else {
        showBuyState(false)
      }
    }
  },
  methods: {
    ...mapMutations('service', ['SET_TOKEN']),
    toPay (id) {
      paymentIntents({
        channel: 6,
        goods: [
          {
            goodsId: id,
            number: 1
          }
        ]
      })
        .then(res => {
          if (res.code === 200) {
            window.Airwallex.redirectToCheckout({
              env: process.env.VUE_APP_PAY_ENV, // Which env('staging' | 'demo' | 'prod') you would like to integrate with
              intent_id: res.data.data.id,
              client_secret: res.data.data.client_secret,
              currency: res.data.data.currency,
              logoUrl: '',
              successUrl: location.protocol + '//' + location.host + '/pay/airwallex?token=' + this.$route.query.token + '&issuccess=1' + '&AccountBalance=' + this.$route.query.AccountBalance,
              cancelUrl: location.protocol + '//' + location.host + '/pay/airwallex?token=' + this.$route.query.token + '&issuccess=2' + '&AccountBalance=' + this.$route.query.AccountBalance,
              failUrl: location.protocol + '//' + location.host + '/pay/airwallex?token=' + this.$route.query.token + '&issuccess=2' + '&AccountBalance=' + this.$route.query.AccountBalance,
              // successUrl: process.env.VUE_APP_PAY_ENV === 'demo' ? 'https://h5dev.bifrost.com/pay/airwallex?issuccess=1&token=' + this.$route.query.token + '&AccountBalance=' + this.$route.query.AccountBalance : 'https://h5.bifrost.com/pay/airwallex?issuccess=1&token=' + this.$route.query.token + '&AccountBalance=' + this.$route.query.AccountBalance,
              // cancelUrl: process.env.VUE_APP_PAY_ENV === 'demo' ? 'https://h5dev.bifrost.com/pay/airwallex?issuccess=2&token=' + this.$route.query.token + '&AccountBalance=' + this.$route.query.AccountBalance : 'https://h5.bifrost.com/pay/airwallex?issuccess=2&token=' + this.$route.query.token + '&AccountBalance=' + this.$route.query.AccountBalance,
              // failUrl: process.env.VUE_APP_PAY_ENV === 'demo' ? 'https://h5dev.bifrost.com/pay/airwallex?issuccess=2&token=' + this.$route.query.token + '&AccountBalance=' + this.$route.query.AccountBalance : 'https://h5.bifrost.com/pay/airwallex?issuccess=2&token=' + this.$route.query.token + '&AccountBalance=' + this.$route.query.AccountBalance,
              country_code: res.data.CountryCode,
              googlePayRequestOptions: {
                countryCode: 'SG'
              }
            })
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.airwallex {
  width: 100%;
  min-height: 100vh;
  background: #000000;
  .pay-desc{
    width: 642px;
    margin: 0 auto;
    margin-top: 60px;
    margin-top: 60px;
    text-align: center;
    font-size: 23px;
    font-family: Arial;
    font-weight: 400;
    color: #ffffff;
    p{
      font-size: 23px;
      opacity: 0.3;
    }
  }
  .card-shu {
    margin: 0 auto;
    margin-top: 60px;
    width: 642px;
    display: flex;
    flex-wrap: wrap;

    .card-list {
      width: 309px;
      position: relative;
      height: 250px;
      background: rgba(38, 38, 38, 0.39);
      border-radius: 10px;
      margin-top: 50px;
      .card-list-decs {
        font-size: 38px;
        text-align: center;
        font-family: Roboto;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
        padding-top: 50px;
      }
      .card-list-btn {
        text-align: center;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 94px;
        line-height: 94px;
        background: linear-gradient(147deg, #ffd341 0%, #ffbb1d 100%);
        border-radius: 0px 0px 10px 10px;
        font-size: 33px;
        font-weight: bold;
        color: #000000;
      }
    }
    .card-list:nth-child(even) {
      margin-left: 20px;
    }
  }
  .card-heng-num {
    padding-left: 123px;
    font-size: 38px;
    font-family: Arial Black;
    font-weight: 400;
    color: #ffffff;
  }
  .card-heng-danw {
    padding-left: 23px;
    font-size: 27px;
    font-family: Arial;
    font-weight: 400;
    color: #ffffff;
  }
  .card-heng-title {
    padding-left: 23px;
    font-size: 27px;
    font-family: Arial;
    font-weight: 400;
    color: #ffffff;
  }
  .card-heng {
    margin: 0 auto;
    margin-top: 30px;
    width: 642px;
    height: 92px;
    background: rgba(38, 38, 38, 0.39);
    opacity: 1;
    border-radius: 10px;
    display: flex;
    align-items: center;
    .img {
      width: 44px;
      height: 44px;
      padding-left: 30px;
    }
  }
  .airwallex-title {
    padding-top: 154px;
    text-align: center;
    font-size: 46px;
    font-family: Arial;
    font-weight: bold;
    line-height: 52px;
    color: #ffffff;
  }
  .airwallex-text {
    margin-top: 30px;
    text-align: center;
    font-size: 33px;
    font-family: Arial;
    font-weight: 400;
    line-height: 42px;
    color: #ffffff;
  }
}
</style>
