import axios from 'axios'
// import store from '@/store'
// import router from '@/router'
// import { setUserInfo } from '@/utils/index'
import { Toast } from 'vant'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASEURL_an, // url = base url + request url'/api',
  method: 'post',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (!config.url.includes('wss')) {
      config.headers.noencrypt = 1
      config.headers.notsign = 1
    }
    if (config.method === 'post') {
      config.data = {
        params: config.data,
        common: {
          aKey: 'ANDROID',
          udid: '1f4ea03f5a9e3bbff8791003222e9709',
          appName: 'Netboom',
          uid: '9210228958446210048',
          pName: 'netboom',
          pApplicationId:
            'com.netboom.cloudgaming.vortex_stadia_shadow_GeForce',
          pVersion: 1640,
          token: localStorage.utoken || '',
          t: 1661849383,
          region: 'CN',
          language: 'zh',
          lang: 'en',
          'phone-info': 'samsung,SM-S205DL',
          'sys-version': 'sdk:30,version:11',
          gaid: '66aee192-749b-4a7b-a82f-c40d0d9fdead',
          adid: '01f9f2c7b19cbc3f88b1d3e2d158e817',
          device_id: '031970285cb75245',
          network_type: 0,
          operator_name: 'ROAM',
          appKey: '6908907985b0978014553feb17a332db'
        }
      }
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    if (response.data && [-3102, -3101].includes(response.data.code)) {
      Toast(response.data.msg)
      // Token expired
      // firebase.auth().signOut()
      //   .then(() => {
      //     setUserInfo()
      //     store.commit('app/updateSignState', false)
      //     store.commit('app/updateUserInfo', {})
      //     router.replace('/home')
      //   }).catch((error) => {
      //     console.log(error)
      //   })
    }

    return response.data
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

export default service
